.dialog-bottom-transition-10 {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }
}


.dialog-bottom-transition-30 {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
}
