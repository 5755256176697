@import '~vuetify/src/styles/styles.sass';
@import './_satoshi.scss';
@import './_transitions.scss';

$color-pack: false;
$font-size-root: 14px;
$body-font-family: 'Satoshi-Medium';
$heading-font-family: 'Satoshi-Bold';
$btn-border-radius: 8px;


@each $heading, $style in $headings {
    $headings: map-merge($headings, ($heading: map-merge($style, ('font-family': $heading-font-family))));
}

@each $heading, $style in $headings {
    $headings: map-merge($headings, ($heading: map-merge($style, ('letter-spacing': 0))));
}

$material-light: map-merge($material-light, (text:  (primary: #000000, hint: #8E59FF)));
$material-light: map-merge($material-light, (background: #F7ECDF));

// // The variables you want to modify
// $font-size-root: 14px;
// // For updating SASS lists
// $material-light: map-merge($material-light, ( cards: blue ));
// $btn-border-radius: 0px;
